@font-face {
  font-family: "Barlow Semi Condensed";
  font-style: normal;
  font-weight: 400;
  src: local("Barlow Semi Condensed"),
    url(./assets/fonts/BarlowSemiCondensed.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

body {
  min-height: 100vh;
  display: flex;
  font-weight: 400;
  font-family: "Barlow Semi Condensed";
  margin-bottom: 120px;
}

body,
html,
.App,
#root,
.auth-wrapper {
  width: 100%;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
