@import url(https://fonts.googleapis.com/css?family=Barlow);
.App {
  text-align: center;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

:root {
  /* primary colors - all pages must have */
  --red: #cd163f;
  /*secondary colors - should complement each other and not create tension - no shades */
  --gold: #f9c20a;
  --orange: #f58025;
  --garnet: #8d0034;
  --purple: #770056;
  --dark-blue: #003e7e;
  --light-blue: #00aeef;
  --green: #77c14c;
  --grey: #e1ebf4;
  /* made up colors -- used for consistency but not in branding */
  --dark-grey: #233d4d;
  --light-grey: #f4f4f6;
  --mid-grey: #e6e6e9;
  --light-blue: #90caf9;
}

.bg-RA-gradient {
  background: linear-gradient(124deg,
      #cd163f 0%,
      #f58025 100%) !important;
  background: linear-gradient(124deg,
      var(--red) 0%,
      var(--orange) 100%) !important;
  background-image: linear-gradient(124deg,
      #cd163f 0%,
      #f58025 100%) !important;
  background-image: linear-gradient(124deg,
      var(--red) 0%,
      var(--orange) 100%) !important;
  overflow-y: auto;
}

.bg-white-gradient {
  background: linear-gradient(124deg,
      #e6e6e9 0%,
      #e1ebf4 100%) !important;
  background: linear-gradient(124deg,
      var(--mid-grey) 0%,
      var(--grey) 100%) !important;
  background-image: linear-gradient(124deg,
      #e6e6e9 0%,
      #e1ebf4 100%) !important;
  background-image: linear-gradient(124deg,
      var(--mid-grey) 0%,
      var(--grey) 100%) !important;
  overflow-y: auto;
}

.bg-white {
  background-color: #e1ebf4 !important;
  background-color: var(--grey) !important;
  overflow-y: auto;
}

.bg-dark {
  background-color: #233d4d !important;
  background-color: var(--dark-grey) !important;
}

.bg-green {
  background-color: #77c14c;
  background-color: var(--green);
}

.bg-light-blue {
  background-color: #90caf9;
  background-color: var(--light-blue);
}

.bg-dark-blue {
  background-color: #003e7e;
  background-color: var(--dark-blue);
}

/* button colors */
.btn-success {
  background-color: #77c14c !important;
  background-color: var(--green) !important;
}

.btn-danger {
  background-color: #cd163f !important;
  background-color: var(--red) !important;
}

.btn-warning {
  background-color: #f58025 !important;
  background-color: var(--orange) !important;
}

.btn-info {
  background-color: #90caf9 !important;
  background-color: var(--light-blue) !important;
}

.btn-valve {
  color: #f58025 !important;
  color: var(--orange) !important;
  background-color: #f4f4f6 !important;
  background-color: var(--light-grey) !important;
  border-color: #f58025 !important;
  border-color: var(--orange) !important;
}

.bottom-edge-shadow {
  box-shadow: 0 8px 6px -6px #233d4d;
  box-shadow: 0 8px 6px -6px var(--dark-grey);
}

.top-edge-shadow {
  box-shadow: 0 8px 6px 8px #233d4d;
  box-shadow: 0 8px 6px 8px var(--dark-grey);
}

/* text colors */
.text-white {
  color: #e1ebf4 !important;
  color: var(--grey) !important;
}

.text-dark {
  color: #233d4d !important;
  color: var(--dark-grey) !important;
}

.text-RA-gradient {
  background: linear-gradient(124deg,
      #cd163f 0%,
      #f58025 100%) !important;
  background: linear-gradient(124deg,
      var(--red) 0%,
      var(--orange) 100%) !important;
  -webkit-background-clip: text;
          background-clip: text;
  -webkit-text-fill-color: transparent;
}

.border-gradient-ra {
  border: 5px solid;
  border-image-slice: 1;
  border-width: 5px;
  border-image-source: linear-gradient(to left, #cd163f 0%, #f58025);
  border-image-source: linear-gradient(to left, var(--red) 0%, var(--orange));
}

/* Remove arrow in number type textfield */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.timeline-tooltip {
  font-size: medium;
  font-family: "Barlow";
  text-align: left;
}

.treeitem-udt {
  font-weight: bold;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* display multiline text */
.multiline {
  white-space: pre-wrap;
  text-align: left;
}

/* gpt message list */
.message-list {
  overflow: auto;
  width: 320px !important;
}

.drag-box {
  cursor: move;
}

#form-file-upload {
  height: 8rem;
  width: 100%;
  max-width: 100%;
  text-align: center;
  position: relative;
}

#input-file-upload {
  display: none;
}

#label-file-upload {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 2px;
  border-radius: 1rem;
  border-style: dashed;
  border-color: #cbd5e1;
  background-color: #f8fafc;
}

#label-file-upload.drag-active {
  background-color: #e4e4e4;
}

.upload-button {
  cursor: pointer;
  padding: 0.25rem;
  font-size: 1rem;
  border: none;
  font-family: "Oswald", sans-serif;
  background-color: transparent;
}

.upload-button:hover {
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
}

#drag-file-element {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.text-link:hover {
  cursor: pointer;
}
@font-face {
  font-family: "Barlow Semi Condensed";
  font-style: normal;
  font-weight: 400;
  src: local("Barlow Semi Condensed"),
    url(/static/media/BarlowSemiCondensed.be4872b5.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

body {
  min-height: 100vh;
  display: flex;
  font-weight: 400;
  font-family: "Barlow Semi Condensed";
  margin-bottom: 120px;
}

body,
html,
.App,
#root,
.auth-wrapper {
  width: 100%;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

